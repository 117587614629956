import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import GridBlock from '../GridBlock';
import range from 'lodash/range';
import clsx from 'clsx';
import CarouselChildren from './CarouselChildren';
// import CarouselItems from '../../const/CarouselItems';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: '0 auto',
  },
  '@keyframes show': {
    '0%': {
      opacity: 0
    }
  },
  dotted: {
    marginRight: theme.spacing(2),
    width: 10,
    height: 10,
    borderRadius: '100%',
    // border: `1px solid black`,
    background: theme.palette.grey[400],
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        cursor: "pointer",
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main
      },
    },
  },
  dottedBox: {
    [theme.breakpoints.down('xs')]: {
      width: 200,
      marginLeft: theme.spacing(4),
      '&>*': {
        marginBottom: theme.spacing()
      }
    },
  },
  currentDotted: {
    // borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main
  },
}))


function Carousel({
  activeItemIndex,
  setActiveItemIndex,
  value,
  setTabsValue,
  length,
  items,
  ...props
}) {
  const classes = useStyles(props);
  const [openDialog, setOpenDialog] = useState(false)
  const [postion, setPostion] = useState(0);

  useEffect(() => {
    if (!openDialog) {
      let id = setInterval(() => {
        setActiveItemIndex((activeItemIndex + 1) % length);
      }, 8000);
      return () => clearInterval(id);
    }
  }, [openDialog, activeItemIndex, items, length, setActiveItemIndex]);

  const createDottedNavigation = useMemo(() => {
    return <GridBlock className={classes.dottedBox}>
      {
        range(length).map(i => {
          return <Box
            key={i}
            className={
              clsx(
                { [classes.currentDotted]: activeItemIndex === i },
                classes.dotted)
            }
            onClick={() => {
              setActiveItemIndex(i)
            }}
          />
        })
      }
    </GridBlock>
  }, [classes, activeItemIndex, length, setActiveItemIndex])

  const swipeStart = useCallback(
    (e) => setPostion(e.changedTouches[0].clientX),
    [],
  )

  const swipeEnd = useCallback(
    (e) => {
      let pos = postion - e.changedTouches[0].clientX;
      if (pos > 0) {
        setActiveItemIndex((activeItemIndex + 1) % length)
      }
      else if (pos < 0) {
        if (activeItemIndex > 0) setActiveItemIndex(activeItemIndex - 1)
        else setActiveItemIndex(length - 1)
      }
    },
    [activeItemIndex, setActiveItemIndex, postion, length],
  )

  return (
    <GridBlock
      justify="center"
      alignItems="center"
      direction="column"
      className={classes.mainContainer}
    >
      <Box onTouchStart={swipeStart} onTouchEnd={swipeEnd}>
        <CarouselChildren
          items={items}
          activeItemIndex={activeItemIndex}
          setActiveItemIndex={setActiveItemIndex}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          length={length}
        />
      </Box>
      {createDottedNavigation}
    </GridBlock >

  )
}


export default Carousel
