import React from 'react'
import GridBlock from './GridBlock';
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx';
import Title from './Title';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '50px 100px',
    [theme.breakpoints.only('md')]: {
      paddingLeft: ` ${theme.spacing(6)}px`,
      paddingRight: ` ${theme.spacing(6)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: ` ${theme.spacing(2)}px`,
      paddingRight: ` ${theme.spacing(2)}px`,
    }
  },
  childBox: {
    ...theme.maxResolution,
    [theme.breakpoints.down('md')]: {
      ...theme.maxResolutionMd,
    }
  }
}))

function ContentContainer({
  id,
  props,
  children,
  className,
  title,
  alignContent = "center",
  alignItems,
  justify
}) {
  const classes = useStyles(props)
  return (
    <GridBlock
      id={id}
      className={clsx(classes.root, className)}
      direction={"column"}
      alignContent={alignContent}
      alignItems={alignItems}
      justify={justify}
    >
      <Box className={classes.childBox}>
        {title && <Title title={title} />}
        {children}
      </Box>
    </GridBlock>

  )
}

export default ContentContainer;
