import React, { useCallback, memo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import ReplyIcon from '@material-ui/icons/Reply';
import { animateScroll as scroll } from 'react-scroll';
import RouterPath from '../../const/RouterPath';
import withWidth from '@material-ui/core/withWidth';
import ImageAnimation from './ImageAnimation';

const useStyles = makeStyles(theme => ({
  contents: {
    margin: '30px 0 50px 0',
    [theme.breakpoints.down('md')]: {
      width: 260,
      textAlign: 'center'
    }
  },
  button: {
    height: 70,
    width: 300,
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 55,
      fontSize: 18,
    }

  },
  endIcon: {
    transform: 'rotateY(180deg)'
  }
}))

function Home({ width, ...props }) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const handleClick = useCallback(
    () => {
      scroll.scrollTo(document.getElementById(RouterPath.anchors.ESTIMATION).offsetTop - 50)
    },
    []
  )

  return (
    <GridBlock
      alignItems='center'
      justify='center'
      direction='column'
    >
      <ImageAnimation width={width} />
      <Typography variant='h5' className={classes.contents}>
        {t('home_context')}
      </Typography>
      <Button
        className={classes.button}
        color='primary'
        variant='contained'
        onClick={handleClick}
        endIcon={<ReplyIcon />}
        classes={{
          endIcon: classes.endIcon
        }}
      >
        {t("home_context_button")}
      </Button>
    </ GridBlock>
  )
}

export default memo(withWidth()(Home));
