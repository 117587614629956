export const idTab = [
  "name_surname",
  "email",
  "phone",
  "country"
]

export const likeList = [
  "checkbox_like1",
  "checkbox_like2",
  "checkbox_like3"
]

export const scopeList = [
  "Design( UX/UI )",
  "React",
  "Node( js/ts )",
  "React Native",
  "Java( Spring )",
  "Python",
  "Flutter",
  "Solidity( Smart Contracts )"
]

export const initialValues = () => {
  let scope = {}
  scopeList.forEach((x, i) => scope[`checkbox_scope${i + 1}`] = false);
  return {
    name_surname: '',
    email: '',
    phone: '',
    country: '',
    description: '',
    radio: "0",
    scope,
    consent: false
  }
}
