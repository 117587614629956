const anchors = {
  HOME: 'home',
  ABOUT: 'about',
  CONTACT: 'contact',
  TECHNOLOGIES: "technologies",
  // TEAM: 'team',
  ESTIMATION: 'estimation',
  PROJECTS: 'projects',
  CLIENTS: 'clients'
}

const path = {
  HOME: '/',
}

export default {
  ...path,
  anchors
}
