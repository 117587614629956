import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import clsx from 'clsx'

const style = theme => ({
  column: {
    width: '100%'
  },
  flexGrow: {
    flexGrow: 1
  }
})

function GridBlock ({
  direction,
  className,
  itemClassName,
  children,
  alignItems,
  spacing,
  justify,
  wrap,
  column = false,
  onClick,
  classes,
  flexGrow = false,
  xs,
  sm,
  md,
  lg,
  xl,
  ...other
}) {
  const elements = useMemo(() => {
    return React.Children.map(children, child => {
      if(child ===null) return ""
      return <Grid
        item
        className={clsx(itemClassName, {
          [classes.column]: column,
          [classes.flexGrow]: flexGrow
        })}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      >
        {child}
      </Grid>
    })
  }, [children, itemClassName, classes, flexGrow, column,xs,sm,md,lg,xl])

  return (
    <Grid
      {...other}
      container
      wrap={wrap}
      spacing={spacing}
      justify={justify}
      onClick={onClick}
      className={className}
      alignItems={alignItems}
      direction={column ? 'column' : direction}
    >
      {elements}
    </Grid>
  )
}

GridBlock.propTypes = {
  className: PropTypes.string
}

GridBlock.defaultProps = {
}

export default memo(withStyles(style)(GridBlock))
