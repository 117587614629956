import React, { memo } from 'react'
import { FormControlLabel, Checkbox as CheckboxUI, FormHelperText, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formHelper: {
    margin: 0,
    marginTop: 8,
    paddingLeft: 32,
  },
  error: {
    color: "red"
  }
})
);

function Checkbox({
  id,
  label,
  disabled,
  checkboxClassName,
  fullWidth,
  className,
  labelClassName,
  required,
  tab
}) {
  const classes = useStyles();

  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext();

  let oldId = id
  id = tab ? tab : id

  let {
    name,
    onBlur,
    onChange,
    value,
  } = getFieldProps(id);

  if (tab) {
    value = value[oldId]
    name = oldId
    onChange = () => {
      setFieldValue(`${tab}.${name}`, !value)
    }
  }


  const fieldMeta = getFieldMeta(id);


  const isError = !!fieldMeta.error && fieldMeta.touched
  const helperText = fieldMeta.error || '';

  const checkbox = <CheckboxUI
    color='primary'
    name={name}
    checked={value}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    className={checkboxClassName}
    classes={{
      root: clsx({ [classes.error]: isError }),
    }}
  />
  if (Boolean(label)) {
    return <>
      <FormControlLabel
        className={className}
        style={fullWidth ? { width: '100%' } : undefined}
        control={checkbox}
        label={label}
        classes={{
          root: clsx({ [classes.error]: isError }),
          label: labelClassName
        }}
      />
      {required &&
        <FormHelperText
          error={isError}
          classes={{
            root: classes.formHelper,
          }}
        >
          {isError ? helperText : " "}
        </FormHelperText>
      }
    </>
  }
  return checkbox;
}

export default memo(Checkbox)
