import React, { memo, useEffect, useCallback } from 'react'
import { Link as LinkRS, animateScroll as scroll } from 'react-scroll'
import RouterPath from '../const/RouterPath';
import { Link as LinkRRD } from "react-router-dom";

const Link = ({ label, to, routerLink = false, onClose= () => {} }) => {

  useEffect(() => {
    const hash = window.location.hash.slice(1)
    if (document.getElementById(hash)) {
      if (window.location.hash === "" &&
        window.location.pathname === RouterPath.HOME) {
        scroll.scrollToTop();
      }
      else scroll.scrollTo(document.getElementById(hash).offsetTop + 100 )
    }
    // eslint-disable-next-line
  }, [window.location.pathname])

  const handleClickLinkRRD = useCallback(() => scroll.scrollTo(0), [])
  
  // const handleClickLinkRS = useCallback(() => history.push(`${RouterPath.HOME}#${to}`), [history, to])

  const handleClickLinkRS = useCallback(() => onClose(),[onClose])


  if (routerLink) {
    return (
      <LinkRRD
        to={to}
        onClick={handleClickLinkRRD}
      >
        {label}
      </LinkRRD>
    )
  }
  else {
    return (
      <LinkRS
        spy
        smooth={"easeInQuad"}
        to={to}
        offset={-50}
        duration={500}
        activeClass='active'
        onClick={handleClickLinkRS}
      >
        {label.toUpperCase()}
      </LinkRS>
    )
  }
}

export default memo(Link);
