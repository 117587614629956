import { createMuiTheme } from '@material-ui/core/styles'

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ed1c24'
    },
    secondary: {
      main: '#fff'
    },
    lightBackground: {
      main: '#f2f2f2',
    }
  },
  typography: {
    fontFamily: [
      "Ubuntu",
    ],
    fontWeightLight: 100,
    useNextVariants: true,

    cutLines: (num) => ({
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      lineClamp: num
    })

  },
  overrides: {
    MuiBackdrop: {
      "root": {
        backgroundColor: "unset"
      }
    },
    MuiDrawer: {
      "paper": {
        width: 200,
        padding: 24,
        '& a': {
          marginBottom: 24,
        }
      }
    },
    MuiTab: {
      "root": {
        fontSize: 18,
        "&$selected": {
          "color": "#ed1c24"
        }
      }
    },
    MuiAppBar: {
      root: {
        '& a': {
          marginRight: 32,
          color: "white",
          cursor: "pointer",
          textAlign: "center",
          position: "relative",
          textDecoration: 'unset',
          '&:hover:after': {
            width: '90%',
          },
          '&:after': {
            left: '0',
            right: '0',
            width: '0%',
            content: `''`,
            height: '2px',
            bottom: '-2px',
            margin: 'auto',
            background: '#aaa',
            position: 'absolute',
            color: 'transparent',
            transition: 'all .5s',
          },
        }
      }
    },
  }
})

const theme = {
  maxResolution: {
    maxWidth: 1920,
    margin: '0 auto'
  },
}

export default {
  ...muiTheme,
  ...theme
}