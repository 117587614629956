import React, { memo } from 'react';
import { TextField, FormHelperText, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  formHelper: {
    margin: 0,
    paddingLeft: 8,
  },
})
);


function TextInput({
  id, label, autoComplete, type, required = false, multiline = false, className
}) {
  const classes = useStyles();

  const { getFieldProps, getFieldMeta } = useFormikContext();

  const fieldMeta = getFieldMeta(id);

  const {
    name,
    onBlur,
    onChange,
    value,
  } = getFieldProps(id);

  const isError = !!fieldMeta.error && fieldMeta.touched
  const helperText = fieldMeta.error || '';

  return (
    <>
      <TextField
        id={id}
        label={label}
        name={name}
        autoComplete={autoComplete}
        variant="outlined"
        margin="normal"
        type={type}
        fullWidth
        required={required}
        multiline={multiline}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={isError}

      />
      <FormHelperText
        error={isError}
        classes={{
          root: classes.formHelper,
        }}
      >
        {isError ? helperText : " "}
      </FormHelperText>
    </>
  );
}

export default memo(TextInput)
