import React from 'react'

export default (props) => {
  return (
    <svg {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512.001 512.001" space="preserve">
<g>
	<g>
		<path d="M256,49.25c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10S261.52,49.25,256,49.25z"/>
	</g>
</g>
<g>
	<g>
		<path d="M427.478,144.258l-126.62-70.36c-4.826-2.683-10.916-0.944-13.599,3.884c-2.683,4.828-0.943,10.917,3.884,13.599
			l126.62,70.36c1.538,0.855,3.205,1.261,4.849,1.261c3.514,0,6.922-1.855,8.75-5.145
			C434.045,153.029,432.305,146.941,427.478,144.258z"/>
	</g>
</g>
<g>
	<g>
		<path d="M512,256.136c0.048-3.513-1.752-6.793-4.739-8.642l-72.408-44.774l72.094-41.029c3.11-1.77,5.037-5.067,5.054-8.645
			c0.017-3.579-1.881-6.893-4.975-8.691l-246-143c-3.107-1.806-6.943-1.806-10.051,0l-246,143c-3.094,1.798-4.991,5.113-4.975,8.691
			c0.017,3.578,1.943,6.875,5.054,8.645l72.094,41.029L4.74,247.495c-2.987,1.848-4.787,5.128-4.739,8.642
			c0.048,3.513,1.936,6.743,4.973,8.509l73.589,42.781L4.961,350.362c-3.074,1.793-4.964,5.086-4.961,8.646
			c0.003,3.559,1.897,6.849,4.975,8.638l246,143c1.554,0.903,3.289,1.354,5.025,1.354c1.736,0,3.472-0.452,5.025-1.354l246-143
			c3.077-1.789,4.972-5.079,4.975-8.638c0.003-3.56-1.887-6.852-4.961-8.646l-73.601-42.936l73.589-42.781
			C510.064,262.879,511.952,259.649,512,256.136z M30.057,152.908L256,21.567l225.943,131.341L256,281.494l-154.813-88.105
			L30.057,152.908z M482.128,358.985L256,490.433L29.872,358.985l68.571-40.002l152.532,88.662c1.554,0.903,3.289,1.354,5.025,1.354
			c1.736,0,3.472-0.452,5.025-1.354l152.532-88.662L482.128,358.985z M256,387.433L29.442,255.735l67.509-41.745l154.103,87.701
			c1.533,0.873,3.24,1.309,4.946,1.309s3.413-0.437,4.946-1.309l154.103-87.701l67.51,41.745L256,387.433z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

  )
}
