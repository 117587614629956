import React, { memo, useMemo, useState, useEffect } from 'react'
import ContentContainer from '../../components/ContentContainer';
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import RouterPath from '../../const/RouterPath';
import TechnologiesCard from '../../components/TechnologiesCard';
import CardItems from '../../const/CardItems';
import initialStateCard from './initialStateCard';
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  container: {
    '&>:nth-child(3)': {
      display: "none",
      [theme.breakpoints.down("lg")]: {
        display: "block",
        flexBasis: '100%',
        height: 0,
      }
    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: "space-around",
      '&> *': {
        marginBottom: theme.spacing(2)
      }
    },
  }
}))

function Technologies({ width, ...props }) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [open, setOpen] = useState(initialStateCard)

  useEffect(() => {
    if (width === 'xs' || width === 'sm') {
      let idInterval = setTimeout(() => {
        window.addEventListener('click', function (e) {
          if (!document.getElementById("tutaj").contains(e.target)) {
            setOpen(initialStateCard)
          }
        })
      }, 100);
      return () => clearTimeout(idInterval);
    }
  }, [open, width])

  const generateCards = useMemo(() => {
    const tab = []
    CardItems.forEach((item, i) => {
      if (i === 2) tab.push(<Box key={i + 5} />)
      tab.push(<TechnologiesCard
        id={`card${i + 1}`}
        key={i}
        open={open}
        setOpen={setOpen}
        Avatar={item.avatar}
        title={t(item.title)}
        items={item.items}
      />)
    })
    return tab
  }, [t, open])

  return (
    <ContentContainer
      title={t("technologies_title")}
      id={RouterPath.anchors.TECHNOLOGIES}
      alignContent="stretch"
    >
      <GridBlock id="tutaj" justify="space-between" className={classes.container}>
        {generateCards}
      </GridBlock>
    </ContentContainer>
  )
}

export default memo(withWidth()(Technologies));
