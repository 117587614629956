import React, { memo } from 'react'

import About from '../About/About';
import Technologies from '../Technologies/Technologies';
import Skills from '../Skills/Skills';
// import Team from '../Team/Team';
import Contact from '../Contact/Contact';
import Ribbon from '../Ribbon/Ribbon';
import Estimation from '../Estimation/Estimation';
import Projects from '../Projects/Projects';
import Clients from '../Clients/Clients';

function HomeContent() {

  return <>
    <About />
    <Technologies />
    <Skills />
    <Projects />
    {/* <Team /> */}
    <Ribbon />
    <Clients />
    <Estimation />
    <Contact />
  </>
}

export default memo(HomeContent)
