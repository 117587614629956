import React, { memo, useCallback, useMemo } from 'react'
import {
  Card as CardUI, CardContent, Typography,
  Box, Collapse, withWidth, makeStyles
} from '@material-ui/core';
import GridBlock from './GridBlock';
import initialStateCard from '../pages/Technologies/initialStateCard';

const useStyles = makeStyles(theme => ({
  card: {
    width: 350,
    height: '100%',
    '&:hover': {
      boxShadow: theme.shadows[9]
    },
    [theme.breakpoints.down('sm')]: {
      width: 260,
      height: 'unset'
    }
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  avatarBox: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: "center",
    width: '100%',
  },
  avatar: {
    marginBottom: theme.spacing(2),
    fill: theme.palette.primary.main,
    width: 100,
    height: 100
  },
  title: {
    color: theme.palette.primary.main,
  },
  image: {
    paddingLeft: theme.spacing(),
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  text: {
    width: 230,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      width: 150,
      paddingLeft: theme.spacing(3)
    }
  },
  itemBox: {
    height: 60,
    cursor: "pointer",
    filter: 'grayscale(100%)',
    '&:hover': {
      filter: "unset"
    }
  }
}))

function TechnologiesCard({ props, id, title, items, Avatar, open, setOpen, width }) {
  const classes = useStyles(props);

  const setState = useCallback(
    () => setOpen({
      ...initialStateCard,
      [id]: !open[id]
    }),
    [open, id, setOpen],
  )

  const createList = useCallback((classes, items) => {
    return items.map((x, i) => {
      return <GridBlock
        key={i}
        alignItems="center"
        justify="space-between"
        className={classes.itemBox}
      >
        {
          x.icon === null ?
            <Box className={classes.image} /> :
            <img className={classes.image} alt={"" + i} src={x.icon} />
        }
        <Typography variant="body2" className={classes.text}>
          {x.text}
        </Typography>
      </GridBlock>
    })
  }, [])

  const withCollapse = useMemo(() => {
    if (width === 'xs' || width === 'sm') {
      return <Collapse in={open[id]} timeout="auto" unmountOnExit>
        {createList(classes, items)}
      </Collapse>

    } else return createList(classes, items)
  }, [width, classes, open, id, createList, items])

  return (
    <CardUI
      className={classes.card}
    >
      <CardContent className={classes.cardContent}>

        <Box onClick={setState} className={classes.avatarBox}>
          <Avatar className={classes.avatar} />

          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Box>

        {withCollapse}

      </CardContent>
    </CardUI>
  )
}

export default memo(withWidth()(TechnologiesCard));
