import React, { memo } from 'react'
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
// import { useTranslation } from 'react-i18next';
import RibbonBackground from '../../images/airplane.png';
// import { Typography } from '@material-ui/core';
import { Parallax } from 'react-parallax';

const useStyles = makeStyles(theme => ({
  skills: {
    color: theme.palette.primary.main,
    height: 300
  },
}))


function Ribbon(props) {
  const classes = useStyles(props);
  // const { t } = useTranslation();

  return (
    <Parallax
      bgImage={RibbonBackground}
      bgImageAlt="ribbon"
      strength={300}
    >
      <ContentContainer
        className={classes.skills}
        // title={t("skills_title")}
        justify="flex-end"
      >
        {/* <Typography>
      {t('ribbon_text')}
    </Typography> */}
      </ContentContainer>
    </Parallax>
  )
}

export default memo(Ribbon)
