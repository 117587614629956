import React, { useCallback, memo } from 'react'
import { Fab, Zoom } from '@material-ui/core'
import { animateScroll as scrollRS } from 'react-scroll'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fab: {
    cursor: "pointer",
    position: 'fixed',
    bottom: 15,
    right: 15,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40
    }
  },
}))

function ScrollToTopButton({props, scroll}) {
  const classes = useStyles(props)

  const scrollToTop = useCallback(() => scrollRS.scrollToTop(), [])

  return (
    <Zoom in={scroll}>
      <Fab
        size="large"
        color="primary"
        className={classes.fab}
        aria-label="scroll back to top"
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom >
  )
}

export default memo(ScrollToTopButton)
