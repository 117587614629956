import React, { memo, useMemo } from 'react'
import { Typography, Box, withWidth } from '@material-ui/core';
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import RouterPath from '../../const/RouterPath';
import Email from '../../images/pictogram/Email';
import Home from '../../images/pictogram/Home';
import Phone from '../../images/pictogram/Phone';

const contactTab = [
  {
    icon: Email,
    text: `info@doittechnology.pl`,
    href: "mailto:info@doittechnology.pl",
  },
  {
    icon: Phone,
    text: '+48 884 217 822',
    href: "tel:+48884217822",
  },
  {
    empty: true
  },
  {
    icon: Home,
    text: 'Barbakan 12/5,\nSzczecin 71-028\nPoland',
    // href: "https://www.google.pl/maps/place/Barbakan+12,+71-028+Szczecin/@53.411855,14.4931474,17z/data=!3m1!4b1!4m6!3m5!1s0x47aa0f2dacee1265:0xbce9050201e3486e!8m2!3d53.411855!4d14.4957223!16s%2Fg%2F11h2c289k8?entry=ttu",
    blank: '_blank'
  }
]

const useStyles = makeStyles(theme => ({
  about: {
    background: "black",
    color: "white",
  },
  mainContainer: {
    padding: `0 ${theme.spacing(8)}px`,
    '& a': {
      textDecoration: "unset",
      color: "white",
      '& :hover': {
        textDecoration: "underline",
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      "&>:nth-child(2)": {
        margin: `${theme.spacing(4)}px 0`
      }
    },
    [theme.breakpoints.between('md', 'lg')]: {
      justifyContent: "space-around",
      '&>:nth-child(3)': {
        flexBasis: "100%",
      },
    },
  },
  itemContainer: {
    width: 300,
    height: 150,
    padding: `0 ${theme.spacing(5)}px`,
    textAlign: "center",
    whiteSpace: "pre-wrap",
    '&>:first-child': {
      marginBottom: theme.spacing(2),
    }
  },
  icon: {
    width: 50,
    height: 50,
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }
}))

function About({ width, ...props }) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const tabs = useMemo(() => contactTab.map((x, i) => {
    const tab = [];
    const Icon = x.icon ? x.icon : <></>;
    if ((width === "lg" || width === 'md') && i === (contactTab.length / 2)) tab.push(<Box key={Math.random()} />)
    if (x.empty) {
      tab.push(<div key={i}>
        <GridBlock
          alignItems="center"
          direction="column"
          className={classes.itemContainer}
        >
        </GridBlock>
      </div>)
    } else {
      tab.push(<a key={i} href={x.href} target={x.blank && x.blank}>
        <GridBlock
          alignItems="center"
          direction="column"
          className={classes.itemContainer}
        >
          <Icon className={classes.icon} />
          <Typography variant='body1'>
            {x.text}
          </Typography>
        </GridBlock>
      </a>)
    }

    return tab
  }), [classes, width])

  return (
    <ContentContainer
      title={t("contact")}
      className={classes.about}
      id={RouterPath.anchors.CONTACT}
      alignContent="stretch"
    >
      <GridBlock justify="space-between" algin="center" className={classes.mainContainer}>

        {tabs}
      </GridBlock>
    </ContentContainer>
  )
}

export default memo(withWidth()(About));


