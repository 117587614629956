import React, { useState, useEffect, memo } from "react";
import MainContrainer from "./MainContrainer";
import AppBar from "../components/AppBar";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { Helmet } from "react-helmet";

function Root() {
  const [scroll, setScroll] = useState(false);
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 100) setScroll(true);
      else setScroll(false);
    });
  }, [scroll]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.screen.availWidth > 2600) setWindowSize(true);
      else setWindowSize(false);
    });
  }, [windowSize, setWindowSize]);

  useEffect(() => {
    window.addEventListener("load", () => {
      if (window.screen.availWidth > 2600) setWindowSize(true);
      else setWindowSize(false);
    });
  }, [windowSize, setWindowSize]);

  return (
    <>
      <Helmet>
        {windowSize ? (
          <meta
            name="viewport"
            content="width=1920, initial-scale=2, maximum-scale=2"
          ></meta>
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        )}
      </Helmet>
      <AppBar scroll={scroll} />
      <MainContrainer />
      <ScrollToTopButton scroll={scroll} />
    </>
  );
}

export default memo(Root);
