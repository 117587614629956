import React, { memo } from 'react'
import GridBlock from '../../components/GridBlock';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Links from '../../components/Links';
import ContentContainer from '../../components/ContentContainer';

const useStyles = makeStyles(theme => ({
  footer: {
    color: "white",
    background: '#323437',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2)
    }
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      alignItems: "flex-end",
      marginBottom: theme.spacing(),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(),
    }
  },
  text: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(4),
    }
  },
  linkBox: {
    '& a': {
      fontSize: 12,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      '& a': {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
      display: "flex",
      flexWrap: 'wrap',
      '& a': {
        marginBottom: theme.spacing(),
        width: '50%'
      }
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(3)
    }
  }
}))

function Footer(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const now = new Date();

  return (
    <ContentContainer
      className={classes.footer}
      alignContent="stretch"
    >
      <GridBlock justify="space-between" alignItems="baseline" className={classes.container}>
        <Box className={classes.linkBox}>
          <Links />
        </Box>
        <Typography variant="body1" className={classes.text}>
          {t("footer_text", { year: now.getFullYear() })}
        </Typography>
      </GridBlock>
    </ContentContainer>
  )
}


export default memo(Footer);
