import reactIcon from '../images/cardIcons/06-c.png';
import nodeJsIcon from '../images/cardIcons/05-c.png';
import javeIcon from '../images/cardIcons/02-c.png';
import uiUxIcon from '../images/cardIcons/08-c.png';

import azureIcon from '../images/cardIcons/09-c.png';
import awsIcon from '../images/cardIcons/03-c.png';

import scrumIcon from '../images/cardIcons/04-c.png';
import kanbanIcon from '../images/cardIcons/01-c.png';

import Support from '../images/pictogram/Support';
import Clouds from '../images/pictogram/Clouds';
import Project from '../images/pictogram/Project';
import Stack from '../images/pictogram/Stack';

import serverlessIcon from '../images/cardIcons/10.png';
import dockerIcon from '../images/cardIcons/11.png';

import autoDepIcon from '../images/cardIcons/autodep.png';
import gcloudIcon from '../images/cardIcons/gcloud.png';
import flutterIcon from '../images/cardIcons/flutter.png';
import blockchainIcon from '../images/cardIcons/blockchain.png';

const stackItems = {
  avatar: Stack,
  title: 'stack',
  items: [
    {
      icon: uiUxIcon,
      text: 'UX /UI'
    },
    {
      icon: reactIcon,
      text: `REACT`
    },
    {
      icon: nodeJsIcon,
      text: 'NODE JS'
    },
    {
      icon: javeIcon,
      text: 'JAVA'
    },
    {
      icon: reactIcon,
      text: `REACT NATIVE`
    },
    {
      icon: flutterIcon,
      text: `FLUTTER`
    },
    {
      icon: blockchainIcon,
      text: "BLOCKCHAIN"
    }
  ]
}

const cloudItems = {
  avatar: Clouds,
  title: 'cloud',
  items: [
    {
      icon: azureIcon,
      text: `AZURE`
    },
    {
      icon: awsIcon,
      text: 'AWS'
    },
    {
      icon: gcloudIcon,
      text: 'Google'
    }
  ]
}

const agileItems = {
  avatar: Project,
  title: 'agile',
  items: [
    {
      icon: scrumIcon,
      text: `SCRUM`
    },
    {
      icon: kanbanIcon,
      text: 'KANBAN'
    }
  ]
}

const servicesItems = {
  avatar: Support,
  title: 'services',
  items: [
    {
      icon: serverlessIcon,
      text: `SERVERLESS`
    },
    {
      icon: dockerIcon,
      text: 'DOCKER'
    },
    {
      icon: autoDepIcon,
      text: "AUTOMATED DEPLOYMENT"
    }
  ]
}

export default [
  stackItems,
  cloudItems,
  agileItems,
  servicesItems
]
