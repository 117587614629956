import React, { memo, useEffect, useRef } from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import './button.scss';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  // "@keyframes loading": {
  //   "100%": {
  //     transform: "rotate(360deg)"
  //   }
  // },
  // "@keyframes scale": {
  //   "0%": { transform: "scale(10)" },
  //   "50%": { transform: "scale(0.2)" },
  //   "70%": { transform: "scale(1.2)" },
  //   "90%": { transform: "scale(0.7)" },
  //   "100%": { transform: "scale(1)" },
  // },

  // "*, *:before, *:after": {
  //   "box-sizing": "border-box",
  //   "outline": "none",
  // },

  // hide: {
  //   "display": "none",
  // },
  // button: {
  //   "position": "relative",
  //   "display": "flex",
  //   "flex-direction": "column",
  //   "align-items": "center",
  //   "justify-content": "center",
  //   "width": "12.5rem",
  //   "margin": "0",
  //   "padding": "1.5rem 3.125rem",
  //   "background-color": "red",
  //   "border": "none",
  //   "border-radius": "0.3125rem",
  //   "box-shadow": "0 12px 24px 0 rgba(0,0,0,0.2)",
  //   "color": "white",
  //   "font-weight": "300",
  //   "text-transform": "uppercase",
  //   "overflow": "hidden",

  //   "&:before": {
  //     "position": "absolute",
  //     "content": "''",
  //     "bottom": "0",
  //     "left": "0",
  //     "width": "0%",
  //     "height": "100%",
  //     // "background-color": "lighten(blue,10%)",
  //     "background-color": "green",
  //   },
  //   "& span": {
  //     "position": "absolute",
  //     "line-height": 0,
  //     "& i": {
  //       transformOrigin: "center center",
  //     },
  //     "& :nth-of-type(1)": {
  //       top: "50%",
  //       transform: "translateY(-50%)",
  //     },
  //     "& :nth-of-type(2)": {
  //       top: "100%",
  //       transform: "translateY(0%)",
  //       "font-size": 24,
  //     },
  //     "& :nth-of-type(3)": {
  //       display: "none",
  //     }
  //   }
  // },
  // active: {
  //   "background-color": "blue",
  //   "&:before": {
  //     width: "100%",
  //     transition: "width 3s linear",
  //   },
  //   "& span": {
  //     "& :nth-of-type(1)": {
  //       top: "-100%",
  //       transform: "translateY(-50%)",
  //     },
  //     "& :nth-of-type(2)": {
  //       top: "50%",
  //       transform: "translateY(-50%)",
  //       "& svg": {
  //         animation: "loading 500 linear infinite",
  //       }
  //     },
  //     "& :nth-of-type(3)": {
  //       display: "none",
  //     }
  //   }
  // },
  // finished: {
  //   // "background-color": "lighten(blue,10%)",
  //   "background-color": "pink",
  //   "& .submit": {
  //     display: "none",
  //   },
  //   "& .loading": {
  //     display: "none",
  //   },
  //   "& .check": {
  //     display: " block !important",
  //     "font-size": "24px",
  //     animation: "scale 0.5s linear",
  //     "& svg": {
  //       "transform-origin": "center center",
  //     }
  //   },
  // },
  error: {
    fontSize: 10,
    marginLeft: theme.spacing(2),
    width: 80,
  }
}))


function SendButton({ errorMail, buttonAnimation, buttonAnimationEnd, setButtonAnimationEnd, label, ...props }) {
  const classes = useStyles(props);

  const button = useRef(null)

  useEffect(() => {
    if (buttonAnimation) {
      button.current.classList.toggle('active')
    }
    if (buttonAnimationEnd) {
      button.current.classList.add('finished')
      const timer = setTimeout(() => {
        setButtonAnimationEnd(false)
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (!buttonAnimationEnd && !buttonAnimation && !errorMail) {
      button.current.classList.remove('active')
      button.current.classList.remove('finished')
    }
  }, [buttonAnimation, buttonAnimationEnd, errorMail,setButtonAnimationEnd])


  return (
    <div className="btn">
      <button ref={button} className="button">
        <span className="submit">{label}</span>
        <span className="loading">
          <CachedIcon />
        </span>
        <span className="check">
          {errorMail ?
            <div style={{display: "flex", alignItems: "center"}}>
              <ErrorIcon />
              <Typography className={classes.error}>
                {"Something went wrong"}
              </Typography>
            </div>
            : <CheckIcon />}
        </span>
      </button>
    </div >
  )
}

export default memo(SendButton)
