import React, { useMemo, useState, useCallback } from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Typography, Box, Hidden } from '@material-ui/core';
import GridBlock from '../GridBlock';
import clsx from 'clsx';
import ImageDialog from '../ImageDialog';

const useStyles = makeStyles(theme => ({
  '@keyframes show': {
    '0%': {
      opacity: 0
    }
  },
  contentContainer: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
  },
  cardBox: {
    display: 'flex',
    width: 'unset',
    boxShadow: theme.shadows[6],
    margin: `0 ${theme.spacing(2)}px`,

    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse",
    },

    "&>:first-child": {
      background: `linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(126,126,126,1) 70%, rgba(226,226,226,1) 100%)`,
      borderBottomLeftRadius: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        borderTopLeftRadius: 'unset',
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
      }
    },
    "&>:nth-child(2)": {
      width: 300,
      padding: theme.spacing(3),
      background: theme.palette.primary.main,
      color: 'white',
      textAlign: "left",

      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(6)
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
        textAlign: "justify",
        borderBottomRightRadius: 'unset',
        borderTopRightRadius: theme.spacing(2),
        borderTopLeftRadius: theme.spacing(2),
        width: 700,
        height: 200,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2),
        borderBottomRightRadius: 'unset',
        width: 550,
        height: 150,
        '& p': {
          fontSize: 12
        }
      },
      [theme.breakpoints.only('xs')]: {
        '& p': {
          fontSize: 11
        },
        width: 250,
        height: 200,
      }
    }
  },
  animation: {
    opacity: 1,
    animationName: '$show',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
  },
  title: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    }
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.only('xl')]: {
      width: 1000,
      height: 550,
    },
    [theme.breakpoints.only('lg')]: {
      width: 700,
      height: 450,
    },
    [theme.breakpoints.only('md')]: {
      width: 700,
      height: 350,
    },
    [theme.breakpoints.only('sm')]: {
      width: 550,
      height: 350,
    },
    [theme.breakpoints.only('xs')]: {
      width: 250,
      height: 150,
    }
  },
  image: {
    margin: `${theme.spacing(6)}px 0`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
    width: '80%',
    objectFit: "contain"
  }
}))

function CarouselChildren({
  length, items, activeItemIndex, setActiveItemIndex,
  openDialog, setOpenDialog, ...props
}) {
  const classes = useStyles(props);

  const [currentImage, setCurrentImage] = useState()

  const moveLeft = useCallback(
    (activeItemIndex) => {
      if (activeItemIndex > 0) setActiveItemIndex(activeItemIndex - 1)
      else setActiveItemIndex(length - 1)
    },
    [setActiveItemIndex, length],
  )

  const moveRight = useCallback(
    (activeItemIndex) => {
      setActiveItemIndex((activeItemIndex + 1) % length)
    },
    [setActiveItemIndex, length],
  )
  const createChildren = useCallback(() => items.map((x, i) => (
    <Box key={i} justify="space-between" className={classes.cardBox}>
      <Box className={classes.imageBox}>
        <img
          src={x.image}
          alt="preview-projects"
          className={clsx(classes.image, classes.animation)}
          onClick={() => {
            setOpenDialog(true)
            setCurrentImage(x.image)
          }}
        />
      </Box>
      <GridBlock alignItems="center" direction="column">
        {/* <Typography variant='body1' className={clsx(classes.title, classes.animation)}>
          {x.title}
        </Typography> */}
        <Typography variant='body1' className={classes.animation}>
          {x.content}
        </Typography>
      </GridBlock>
    </Box >
  )), [classes, items, setOpenDialog])

  const createButton = useMemo(() => {
    return (Icon, func) => {
      return <IconButton onClick={() => func(activeItemIndex)}>
        <Icon />
      </IconButton>
    }
  }, [activeItemIndex])

  return <>
    <GridBlock wrap="nowrap" justify="center" alignItems="center" className={classes.contentContainer}>
      <Hidden smDown>
        {createButton(ArrowBackIosIcon, moveLeft)}
      </Hidden>
      {createChildren()[activeItemIndex]}
      <Hidden smDown>
        {createButton(ArrowForwardIosIcon, moveRight)}
      </Hidden>
    </GridBlock >
    <ImageDialog image={currentImage} open={openDialog} onClose={() => setOpenDialog(false)} />
  </>
}

export default CarouselChildren
