import React, { useState, useMemo, useEffect, useCallback } from 'react'
import ContentContainer from '../../components/ContentContainer';
import RouterPath from '../../const/RouterPath';
import { useTranslation } from 'react-i18next';
import Carousel from '../../components/Carousel/Carousel';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import GridBlock from '../../components/GridBlock';
import _ from 'lodash';
import CarouselItems from '../../const/CarouselItems';
import range from 'lodash/range';

const tabTab = [
  'mobile',
  'web',
  'dedicated'
]

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  tab: {
    [theme.breakpoints.only('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  }
}))

function Projects(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [tabsValue, setTabsValue] = useState(0)
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const itemsCounter = useCallback((key) => {
    let counter = 0;
    CarouselItems.forEach(x => {
      if (x.key === key) counter++
    });
    return counter
  }, [])

  let tab1Length = itemsCounter("mobile")
  let tab2Length = tab1Length + itemsCounter("web")
  let tab3Length = tab2Length + itemsCounter("dedicated")

  const indexTab = useCallback(
    () => {
      let tab = []
      range(tab3Length).forEach((i) => {
        tab.push(i)
      });
      return tab
    },
    [tab3Length],
  )

  useEffect(() => {
    if (tabsValue === 0) {
      if (!indexTab().slice(1, tab1Length).includes(activeItemIndex)) {
        setActiveItemIndex(0)
      }
    }
    if (tabsValue === 1) {
      if (!indexTab().slice(tab1Length, tab2Length).includes(activeItemIndex)) {
        setActiveItemIndex(3)
      }
    }
    if (tabsValue === 2) {
      if (!indexTab().slice(tab2Length + 1, tab3Length).includes(activeItemIndex)) {
        setActiveItemIndex(8)
      }
    }
    //eslint-disable-next-line
  }, [tabsValue, tab1Length, tab2Length, tab3Length])

  useEffect(() => {
    if (activeItemIndex >= 0 && activeItemIndex < tab1Length) {
      setTabsValue(0)
    }
    if (activeItemIndex >= tab1Length && activeItemIndex < tab2Length) {
      setTabsValue(1)
    }
    if (activeItemIndex >= tab2Length && activeItemIndex < tab3Length) {
      setTabsValue(2)
    }
  }, [activeItemIndex, tab1Length, tab2Length, tab3Length])

  const handleChange = (event, newValue) => setTabsValue(newValue)

  const TabPanel = (props) => {
    const { value, index } = props;
    return value === index && <Carousel
      activeItemIndex={activeItemIndex}
      setActiveItemIndex={setActiveItemIndex}
      length={CarouselItems.length}
      items={CarouselItems}
      setTabsValue={setTabsValue}
    />
  }

  const createTabs = useMemo(() => {
    return tabTab.map((x, i) => (
      <Tab
        id={i}
        key={i}
        label={t(x)}
        className={classes.tab}
      />))
  }, [classes, t])

  const createTabPanels = useMemo(() => {
    return _.range(3).map((x, i) => <TabPanel key={i} value={tabsValue} index={i} />)
    //eslint-disable-next-line
  }, [tabsValue, setActiveItemIndex, activeItemIndex])

  return (
    <ContentContainer
      title={t("project_title")}
      id={RouterPath.anchors.PROJECTS}
      alignContent="stretch"
    >
      <GridBlock alignItems="center" justify="center" direction="column">
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabs}
        >
          {createTabs}
        </Tabs>
        {createTabPanels}
      </GridBlock>
    </ContentContainer>
  )
}

export default Projects
