import mobile1 from '../images/projects/mobile.png'
import mobile2 from '../images/projects/mobile2.png'
import mobile3 from '../images/projects/taifho.png'


import dedicated1 from '../images/projects/foto-01.png'
import dedicated2 from '../images/projects/foto-03.png'
import dedicated3 from '../images/projects/foto-05.png'
import dedicated4 from '../images/projects/micro-management.png'
import dedicated5 from '../images/projects/TimeLinePirate.png'


import web1 from '../images/projects/foto-02.png'
import web2 from '../images/projects/foto-04.png'
import web3 from '../images/projects/PersonalData.png'
import web4 from '../images/projects/Spar-09.png'
import web5 from '../images/projects/Treo.png'

export default [
  {
    key: "mobile",
    image: mobile1,
    // title: "title",
    content: 'Mobile application for managing prescriptions. The Doctor can make a preception, filling all the information about the patient, the medicines and doses, and then save it. Later, the prescription is generated, and can be printed out, or generated as a QR code and send directly to a Pharmacy without the need for the patien to have the physical prescriotions with himself.'
  },
  {
    key: "mobile",
    image: mobile2,
    // title: "title",
    content: 'Mobile application for reading ithe information from physical Business cards and extracting them to the app. The user have the possibility to scan those cards and the application will read the data and save it. Apart from that, there is also an option to create events and assign the extracted contacts to the specific events, to group the contact and manage them.'
  },
  {
    key: "mobile",
    image: mobile3,
    // title: "title",
    content: 'An absorbing mobile game where the goal is to move all of your pawns across the board. This japanese board game is somewhat mix of chess and checkers – it require a lot of planning and strategic thinking. There are 3 levels of difficulty, an option to play with advanced AI, animated movements, an option to login via Facebook or Google account and even a ranking system.'
  },
  {
    key: "web",
    image: web1,
    // title: "title",
    content: 'A web application made for checking the efficiency of the employees. The application is based on multiple questionnaries that are made specially to measure the activity of the workers. Based on those information, the Employer can reassing people and better distribute the task among them.'
  },
  {
    key: "web",
    image: web2,
    // title: "title",
    content: 'A web application done specially for creating and managing events that are meant to be different activities for groups of children and their patrons. There are a lot of different possibilieties: creating personal accounts, managing events, creating and distributing badges as a form of reward for completing the activity, and also checking the history of closed events.'
  },
  {
    key: "web",
    image: web3,
    // title: "title",
    content: 'Web application done for a swedish partner, designed as a platform to sell personal information of the people that agreed for it. The client have a variety of different options, like creating personal account and search for the specific pieces of information, based on the advanced filtering methods. The buyer also have the opportunity to buy only the data that is new, excluding the data he/she already own.'
  },
  {
    key: "web",
    image: web4,
    // title: "title",
    content: 'Design project made for SPAR stores. The design was carefully created to go on with the theme of the stores itself. The idea was to create a new, freash webpage view that will match the current standards.'
  },
  {
    key: "web",
    image: web5,
    // title: "title",
    content: 'A web service for managing export and import pallet transport done between the EU countries. The application is designed to calculate the cost of the freight based on the pallet type, the dimensions of the wares. The client have the possibility to pick the initial and destination country with the post code.'
  },
  {
    key: "dedicated",
    image: dedicated1,
    // title: "title",
    content: `Dedicated app made for institutions that manage financial transactions. It handles the validation of all the personal data – bank numbers, personal ID's, etc. The user can additionally create statistics about financial operations, export files with all the data of the choosen operations. In case of interest, please contact with us about providing the license.`
  },
  {
    key: "dedicated",
    image: dedicated2,
    // title: "title",
    content: 'Web platform created for selling tickets to the musical events. It was designed to create events, managing the tickets, creating promotions and adding gifts to the order. There are a lot of possibilities for user, from picking up the best places on the event, to adding some gadgets and souvenirs to the basket.'
  },
  {
    key: "dedicated",
    image: dedicated3,
    // title: "title",
    content: 'Dedicated CMS system for managing the application content of the Cracow Opera Ballet Studio. The user have the possibility to manage different sections of the applications, changing the content, etc. It is designed to match the expectations of the client, but also to be simple and self-explanatory.'
  },
  {
    key: "dedicated",
    image: dedicated4,
    // title: "title",
    content: `Dedicated app made for work management of the employees. The system handles such tasks as planning work schedules, time management, salaries, managing records of fixed assets, issues report and many more. In case of interest, please contact with us about proving the license.`
  },
  {
    key: "dedicated",
    image: dedicated5,
    // title:,
    content: 'A dedicated application made for museum. It was designed as a responsive map that showed the places of voyages of the last pirate that sailed Baltic sea – Eryk Pomorski. The user was able to tocuh the timeline to see all the places visited by a pirate. After the date was chosen on the timeline, the map was zooming to the specific spot that was visited in that period.'
  }
]
