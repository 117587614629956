import React, { memo } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header';
import HomeContent from './Home/HomeContent';
import Footer from './Footer/Footer';

function MainContrainer() {
  return <>
    <Header />
    <HomeContent />
    <Footer />
  </>
}

export default memo(MainContrainer);
