import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider as ThemeProviderUI } from "@material-ui/styles";
import Root from "./pages/Root";
import theme from "./theme/theme";
import pjson from "../package.json";

console.table({ version: pjson.version });

function App() {
  return (
    <BrowserRouter>
      <ThemeProviderUI theme={theme}>
        <Root />
      </ThemeProviderUI>
    </BrowserRouter>
  );
}

export default App;
