import React from 'react'

export default (props) => {
  return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 512 512" space="preserve" {...props} >
      <g>
        <g>
          <path d="M512,320.126c0-49.185-40.017-89.202-89.202-89.202c-5.264,0-10.49,0.458-15.627,1.368
            c-1.027-16.464-5.53-32.634-13.176-47.163c-2.219-4.217-7.435-5.837-11.654-3.617c-4.217,2.22-5.836,7.436-3.617,11.654
            c7.469,14.192,11.416,30.232,11.416,46.388c0,0.867-0.03,1.73-0.06,2.589l-0.026,0.819c-0.086,2.791,1.184,5.453,3.408,7.141
            c2.225,1.688,5.127,2.197,7.797,1.36c6.944-2.176,14.19-3.28,21.539-3.28c39.671,0,71.946,32.275,71.946,71.946
            c0,14.321-4.238,28.168-12.088,39.92c-0.301-0.325-0.618-0.632-0.926-0.95c-0.341-0.354-0.68-0.71-1.029-1.056
            c-0.232-0.228-0.47-0.448-0.704-0.672c-0.545-0.521-1.096-1.034-1.659-1.535c-0.088-0.078-0.179-0.154-0.267-0.232
            c-10.506-9.216-24.255-14.821-39.296-14.821c-3.14,0-6.272,0.247-9.362,0.734c-9.31-10.528-22.745-16.667-36.962-16.667
            c-2.309,0-4.606,0.161-6.882,0.481c-4.678-13.326-13.048-25.267-24.12-34.229c-13.544-10.964-30.616-17.003-48.071-17.003
            c-39.396,0-71.931,29.937-76.049,68.258c-2.269-0.262-4.56-0.395-6.861-0.395c-0.969,0-1.931,0.027-2.887,0.073
            c-0.367,0.017-0.728,0.052-1.093,0.076c-0.58,0.039-1.162,0.077-1.737,0.133c-0.464,0.044-0.922,0.105-1.382,0.16
            c-0.463,0.056-0.925,0.11-1.385,0.177c-0.526,0.077-1.048,0.165-1.569,0.256c-0.374,0.065-0.749,0.128-1.121,0.201
            c-0.585,0.112-1.164,0.236-1.742,0.366c-0.286,0.065-0.572,0.129-0.855,0.198c-0.649,0.155-1.293,0.321-1.934,0.498
            c-0.181,0.051-0.362,0.102-0.544,0.154c-0.729,0.209-1.453,0.429-2.17,0.665c-0.044,0.015-0.088,0.03-0.133,0.045
            c-18.265,6.058-32.598,20.797-38.089,39.313c-0.009,0.028-0.016,0.057-0.024,0.085c-0.243,0.821-0.471,1.648-0.677,2.484
            c-0.001,0.003-0.001,0.008-0.002,0.011c-0.424,1.713-0.784,3.451-1.054,5.219H90.144c-40.19,0-72.887-32.698-72.887-72.889
            c0-40.19,32.697-72.887,72.887-72.887c6.194,0,12.365,0.783,18.343,2.328c3.497,0.899,7.193-0.469,9.251-3.441
            c10.552-15.234,27.891-24.33,46.381-24.33c6.078,0,12.089,0.977,17.869,2.906c2.31,0.772,4.842,0.531,6.967-0.669
            c2.124-1.196,3.641-3.237,4.179-5.614c1.972-8.724,5.028-16.94,9-24.536c0.245-0.327,0.47-0.671,0.669-1.034
            c12.807-23.498,34.856-41.121,60.495-48.35c0.305-0.086,0.603-0.19,0.893-0.307c8.37-2.244,17.157-3.444,26.206-3.444
            c27.917,0,53.798,11.238,72.876,31.644c3.254,3.48,8.714,3.665,12.196,0.41c3.482-3.255,3.664-8.715,0.41-12.196
            c-22.053-23.586-53.21-37.114-85.482-37.114c-8.404,0-16.75,0.919-24.887,2.695c-8.401-13.683-23.304-22.196-39.745-22.196
            c-1.111,0-2.22,0.039-3.323,0.116c-4.144-28.959-29.112-51.289-59.198-51.289c-25.272,0-47.187,15.441-55.882,38.449
            c-1.242-0.118-2.495-0.178-3.75-0.178c-10.942,0-21.302,4.561-28.685,12.427c-2.072-0.276-4.16-0.415-6.25-0.415
            c-25.983,0-47.12,21.139-47.12,47.122c0,25.983,21.138,47.122,47.12,47.122h61.542c4.765,0,8.628-3.863,8.628-8.628
            s-3.863-8.628-8.628-8.628h-61.54c-16.467,0-29.864-13.398-29.864-29.866c0-16.469,13.398-29.866,29.864-29.866
            c2.536,0,5.067,0.321,7.527,0.957c3.499,0.901,7.19-0.472,9.246-3.442c4.133-5.965,10.922-9.527,18.16-9.527
            c2.384,0,4.739,0.382,6.999,1.136c2.312,0.773,4.844,0.527,6.966-0.669c2.125-1.197,3.642-3.238,4.179-5.615
            c4.405-19.502,21.464-33.123,41.487-33.123c23.459,0,42.545,19.085,42.545,42.543c0,0.366-0.015,0.728-0.027,1.09l-0.013,0.366
            c-0.092,2.793,1.177,5.46,3.402,7.151c2.225,1.691,5.132,2.2,7.8,1.367c2.847-0.892,5.813-1.344,8.814-1.344
            c8.74,0,16.794,3.816,22.279,10.187c-0.3,0.118-0.593,0.25-0.892,0.37c-0.547,0.217-1.086,0.45-1.63,0.675
            c-0.85,0.355-1.7,0.709-2.54,1.083c-1.004,0.444-1.996,0.914-2.989,1.386c-0.341,0.164-0.685,0.321-1.025,0.489
            c-7.837,3.831-15.268,8.525-22.102,14.058c-9.258,7.495-17.272,16.358-23.813,26.178c-0.263,0.394-0.516,0.794-0.774,1.19
            c-0.548,0.844-1.089,1.692-1.616,2.551c-0.14,0.228-0.291,0.449-0.429,0.677H158.26c-4.765,0-8.628,3.863-8.628,8.628
            s3.863,8.628,8.628,8.628h23.406c-1.129,2.834-2.155,5.711-3.058,8.63c-4.763-0.958-9.611-1.44-14.492-1.44
            c-22.175,0-43.077,10.009-57.011,27.041c-5.583-1.061-11.264-1.598-16.963-1.598C40.439,229.037,0,269.477,0,319.181
            c0,49.706,40.439,90.146,90.144,90.146h81.834c4.041,28.667,28.722,50.797,58.487,50.797h151.06c4.765,0,8.628-3.863,8.628-8.628
            s-3.863-8.628-8.628-8.628H230.465c-23.061,0-41.822-18.762-41.822-41.822c0-0.245,0.014-0.487,0.018-0.731
            c0.01-0.548,0.023-1.096,0.053-1.639c0.008-0.126,0.021-0.251,0.029-0.378c0.039-0.589,0.083-1.176,0.146-1.758
            c0.006-0.056,0.015-0.111,0.022-0.167c2.341-20.851,20.134-37.155,41.555-37.155c4.276,0,8.49,0.642,12.527,1.907
            c2.67,0.838,5.578,0.327,7.802-1.365c2.226-1.692,3.493-4.358,3.402-7.154l-0.017-0.472c-0.018-0.513-0.038-1.026-0.038-1.544
            c0-32.662,26.572-59.235,59.233-59.235c27.429,0,51.721,19.396,57.76,46.122c0.538,2.378,2.056,4.418,4.18,5.615
            c2.126,1.197,4.657,1.44,6.968,0.668c3.286-1.098,6.707-1.653,10.166-1.653c10.522,0,20.388,5.177,26.392,13.846
            c2.06,2.975,5.752,4.348,9.256,3.442c3.474-0.899,7.067-1.356,10.676-1.356c0.841,0,1.675,0.032,2.507,0.08
            c0.311,0.018,0.619,0.046,0.927,0.071c0.497,0.039,0.992,0.087,1.485,0.143c0.403,0.046,0.806,0.096,1.206,0.153
            c0.33,0.047,0.657,0.105,0.985,0.16c10.927,1.853,20.447,7.887,26.838,16.407c0.025,0.032,0.049,0.066,0.073,0.098
            c5.271,7.069,8.395,15.826,8.395,25.301c0,23.388-19.029,42.415-42.417,42.415h-29.211c-4.765,0-8.628,3.863-8.628,8.628
            s3.863,8.628,8.628,8.628h29.211c32.904,0,59.673-26.768,59.673-59.672c0-8.986-2.009-17.509-5.582-25.16
            C505.231,359.603,512,340.247,512,320.126z"/>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>
      
  )
}