import React, { memo } from "react";
import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  image: {
    objectFit: "contain",
    height: '80vh'
  },
  paperFullScreen: {
    background: `linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(126,126,126,1) 70%, rgba(226,226,226,1) 100%)`,
    // height: 'unset'
    height: '100vh',
    display: "flex",
    justifyContent: "center"
  },
  title: {
    position: "fixed",
    top: 0,
    right: 0
  }
}))
function ImageDialog({ props, open, image, onClose }) {
  const classes = useStyles(props);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      classes={{
        paperFullScreen: classes.paperFullScreen
      }}
    >
      <DialogTitle className={classes.title}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <img className={classes.image} src={image} alt="preview-projects" />
    </Dialog>
  );
}

export default memo(ImageDialog)
