import React, { useMemo, useState, useEffect } from 'react'
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';
import RouterPath from '../../const/RouterPath';
import ItemsCarousel from 'react-items-carousel';
import GridBlock from '../../components/GridBlock';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import withWidth from '@material-ui/core/withWidth';

const imports = require.context('../../images/clients/', true, /\.png$/);
const tab = Object.values(imports.keys())
const slideLength = tab.length / 2

function Clients({ width, ...props }) {

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    let id = setInterval(() => {
      setActiveItemIndex(activeItemIndex + 1);
    }, 3000);
    return () => clearInterval(id);
  }, [activeItemIndex]);

  const classes = useStyles(props);
  const { t } = useTranslation();

  const firstRow = tab.slice(0, slideLength)
  const secondRow = tab.slice(slideLength)

  const clientsItems = _.range(slideLength)

  const setNumberOfCards = useMemo(() => {
    switch (width) {
      case 'xs':
      case 'sm': return 1;
      case 'md': return 2;
      default: return 3;
    }
  }, [width])

  return (
    <ContentContainer
      title={t("clients_title")}
      className={classes.clients}
      id={RouterPath.anchors.CLIENTS}
      alignContent="stretch"
    >
      <GridBlock justify="center" className={classes.container}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={setNumberOfCards}
          slidesToScroll={1}
          gutter={20}
          infiniteLoop
          classes={{
            itemsWrapper: classes.itemsWrapper,
          }}
        >
          {clientsItems.map((i) => {
            return (
              <Box key={i} className={classes.imageBox}>
                <img loading="lazy" alt={`clients-${i}`} src={imports(firstRow[i])} />
                <img loading="lazy" alt={`clients-${i}`} src={imports(secondRow[i])} />
              </Box>
            )
          })}
        </ItemsCarousel>
      </GridBlock>
    </ContentContainer>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      margin: '0 auto',
      '&>:first-child': {
        width: 300,
      }
    }
  },
  itemsWrapper: {
    [theme.breakpoints.up('md')]: {
      width: '70vw',
    }
  },
  imageBox: {
    textAlign: "center",
    '&>*': {
      width: "60%",
    },
    '&>:first-child': {
      marginBottom: theme.spacing(4),
    }
  }
}))

export default withWidth()(Clients);
