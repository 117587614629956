import React, { memo, useCallback, useMemo, useState } from 'react'
import { Formik, Form } from 'formik';
import TextInput from '../../components/TextInput';
import { useTranslation } from 'react-i18next';
import GridBlock from '../../components/GridBlock';
import { makeStyles, Box } from '@material-ui/core';
import CheckboxGroup from '../../components/CheckboxGroup';
import RadioGroup from '../../components/RadioGroup';
import { idTab, scopeList, likeList, initialValues } from './FormConstants';
import * as Yup from 'yup';
import Checkbox from '../../components/Checkbox';
import SendButton from '../../components/SendButton/SendButton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    '&>:last-child': {
      width: 280,
      [theme.breakpoints.down('xs')]: {
        width: 200,
      },
      '& button': {
        height: 45,
        marginTop: theme.spacing(3)
      }
    }
  },
  group: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '&>*': {
        width: '100%',
      }
    },
    [theme.breakpoints.up('md')]: {
      '&> *': {
        width: '31%',
      },
      '&>:last-child': {
        width: '35%',
      },
    }
  }
}))


function EstimationForm(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [error, setError] = useState(false)
  const [errorMail, setErrorMail] = useState(false)

  const [buttonAnimation, setButtonAnimation] = useState(false)
  const [buttonAnimationEnd, setButtonAnimationEnd] = useState(false)

  const handleSubmit = useCallback((values, e) => {
    if (errorMail) {
      setErrorMail(false)
      setButtonAnimation(false)
      setButtonAnimationEnd(false)
      e.resetForm()
    }
    else {
      setButtonAnimation(true)
      const { country, description, email, name_surname, phone, radio, consent, ...other } = values;
      let obj = { name_surname, email, phone, country, description, like: t(likeList[radio]), scope: [] };

      Object.keys(other.scope).forEach((x, i) => {
        if (other.scope[x]) {
          obj.scope.push(scopeList[i])
        }
      });

      fetch('/api/v1/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      })
        .then((response) => {
          if (response.status !== 200) {
            setErrorMail(response.statusText)
          } else {
            e.resetForm()
          }
          setButtonAnimation(false)
          setButtonAnimationEnd(true)

          return response.json();
        })
    }
  }, [t, errorMail])

  const createTextInput = useMemo(() => idTab.map((x, i) => {
    return <TextInput
      id={x}
      key={i}
      label={t(x)}
      className={classes.textInput}
    />
  }), [t, classes])

  const initialValuesMemo = useMemo(() => initialValues(), [])
  const validationSchemaMemo = useMemo(() => {
    return Yup.lazy((values) => {
      let validateObj = {
        name_surname: Yup.string().required(t('required')),
        email: Yup.string().email(t('email_error')).required(t('required')),
        phone: Yup.number(),
        country: Yup.string(),
        description: Yup.string(),
        consent: Yup.bool().oneOf([true], 'Field must be checked'),
      }

      let checkboxCounter = 0
      Object.values(values.scope).forEach(x => {
        if (x) checkboxCounter += 1
      });

      if (checkboxCounter < 2) {
        validateObj = {
          ...validateObj,
          scope: Yup.array().min(2)
        }
        setError(true)
      } else {
        setError()
      }

      return Yup.object().shape(validateObj)
    })

  }, [t])

  return (
    <Formik
      initialValues={initialValuesMemo}
      validationSchema={validationSchemaMemo}
      onSubmit={handleSubmit}
    >
      <Form>
        <GridBlock justify="space-between" alignItems="center" column className={classes.container}>
          <GridBlock justify="space-between" className={classes.group}>
            <Box className={classes.textInputBox}>
              {createTextInput}
            </Box>
            <RadioGroup id="radio" items={likeList} label={t('checkbox_group1_label')} />
            <CheckboxGroup id="scope" items={scopeList} label={t('checkbox_group2_label')} error={error} />
          </GridBlock>
          <TextInput
            id="description"
            label={t("description")}
            multiline
          />
          <Checkbox id="consent" label={t("consent")} required />
          <SendButton
            label={t("home_context_button")}
            buttonAnimation={buttonAnimation}
            buttonAnimationEnd={buttonAnimationEnd}
            setButtonAnimationEnd={setButtonAnimationEnd}
            errorMail={errorMail}
          />
        </GridBlock>
      </Form>
    </Formik >
  )
}

export default memo(EstimationForm)
