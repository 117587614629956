import React, { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import Radio from './Radio';
import { RadioGroup as RadioGroupUI } from '@material-ui/core';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px 0`
    }
  },
}));

function RadioGroup({
  items,
  label,
  id
}) {
  const classes = useStyles();
  const { t } = useTranslation()

  const { getFieldProps } = useFormikContext();

  const {
    name,
    onBlur,
    onChange,
    value,
  } = getFieldProps(id);

  const radioList = useMemo(() => items.map((x, i) => {
    return <Radio key={i} value={"" + i} label={t(x)} />
  }), [items, t])

  return (
    <FormControl
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroupUI
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        {radioList}
      </RadioGroupUI>
    </FormControl>
  )
}

export default memo(RadioGroup)
