import React, { memo, useMemo, useEffect, useState, useRef } from 'react'
import { Hidden, makeStyles, Box } from '@material-ui/core'
import clsx from 'clsx';
import doImage from '../../images/do.png';
import itImage from '../../images/it.png';
import technologyImage from '../../images/technology.png';
import doTechnologyImage from '../../images/doTechnology.png';
import GridBlock from '../../components/GridBlock';

const useStyles = makeStyles(theme => ({
  '@keyframes slide': {
    '0%': {
      height: 0
    }
  },
  '@keyframes show': {
    '0%': {
      opacity: 0
    }
  },
  box: {
    position: 'absolute',
    top: 0,
    background: `linear-gradient(180deg, rgba(255,0,0,0) 0%, ${theme.palette.primary.main} 70%)`,
    width: 75,
    animationName: '$slide',
    animationDuration: '1.0s',
    animationTimingFunction: 'ease-in-out',
  },
  itImage: {
    zIndex: 1,
    opacity: 1,
    animationName: '$show',
    animationDuration: '2.7s',
    animationTimingFunction: 'ease-in-out',
    [theme.breakpoints.down('md')]: {
      width: 80
    }
  },
  doTechnologyImage: {
    width: 130
  }
}))

function ImageAnimation({ width, ...props }) {
  const classes = useStyles(props);
  const [redBoxHeight, setRedBoxHeight] = useState(0)
  const itImageRef = useRef()

  const setHeight = useMemo(() => {
    return (itImageRef) => {
      let { height, offsetTop } = itImageRef.current
      if (height <= 0) height = 50
      if (width === "lg" || width === "xl") height -= 10
      else height += 10
      return offsetTop + height
    }
  }, [width])

  useEffect(() => {
    setRedBoxHeight(setHeight(itImageRef))
  }, [itImageRef,setHeight])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setRedBoxHeight(setHeight(itImageRef))
    })
  }, [setHeight])

  const imageBox = useMemo(() => {
    const condition = width !== "lg" && width !== "xl"
    return <GridBlock alignItems='center' justify='center'>
      <img
        alt="do"
        src={condition ? doTechnologyImage : doImage}
        className={clsx({ [classes.doTechnologyImage]: condition })}
      />
      <Box style={{ margin: '0 10px 0 5px', display: "flex", justifyContent: "center" }}>
        <img
          ref={itImageRef}
          alt="id" src={itImage} className={classes.itImage} />
        <Box className={classes.box} style={{ height: redBoxHeight }} />
      </Box>
      <Hidden mdDown>
        <img alt="technology" src={technologyImage} />
      </Hidden>
    </GridBlock>
  }, [width, classes, redBoxHeight])

  return imageBox
}

export default memo(ImageAnimation);
