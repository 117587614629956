import React, { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px 0`
    }
  },
  formHelper: {
    margin: 0,
    marginTop: theme.spacing(-2),
    paddingLeft: theme.spacing(3),
  },
}));

function CheckboxesGroup({ items, label, error }) {
  const classes = useStyles();
  const { t } = useTranslation()

  const checkboxList = useMemo(() => items.map((x, i) => {
    return <Checkbox id={`checkbox_scope${i + 1}`} key={i} label={t(x)} tab="scope" />
  }), [items, t])

  return <>
    <FormControl
      required
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {checkboxList}
      </FormGroup>
    </FormControl>
    <FormHelperText
      error={error}
      classes={{
        root: classes.formHelper,
      }}
    >
      {error ? t('checkbox_group_error') : " "}
    </FormHelperText>
  </>
}

export default memo(CheckboxesGroup)
