import React, { memo, useMemo, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { AppBar as AppBarUI, Toolbar, Box, Drawer, IconButton, Hidden } from '@material-ui/core'
import LogoDoIt from '../images/logo.png'
import LogoDoItMobile from '../images/logo-mobile.png'
import { animateScroll as scrollRS } from 'react-scroll'
import Links from './Links'
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: '0 auto'
  },
  appBar: {
    background: 'unset',
    transition: 'background 0.2s',
    position: 'fixed',
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    height: 70,
    [theme.breakpoints.down('md')]: {
      height: 60,
      alignItems: 'normal',
      justifyContent: "center",
      background: 'black',
    },
  },
  appBarScroll: {
    background: 'white',
    transition: 'background 0.3s',
    '& a': {
      color: 'black',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&:after': {
        background: "unset"
      }
    },

  },
  toolbar: {
    ...theme.maxResolution,
    margin: "unset",
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(7),
      width: '100%',
      '&>:first-child': {
        flexGrow: 1,
        marginLeft: theme.spacing(10)
      },
      '&>:last-child': {
        marginRight: theme.spacing(2)
      },
    },
  },
  logo: {
    paddingTop: theme.spacing(5),
    cursor: "pointer",
    width: 120,
    transition: 'width 0.3s',
    [theme.breakpoints.down('md')]: {
      paddingTop: "unset",
      width: 60
    },
    '&:hover': {
      opacity: 0.9,
    }
  },
  logoScroll: {
    paddingTop: theme.spacing(),
    width: 80,
    transition: 'width 0.3s'
  }
}))

function AppBar({ props, scroll, width }) {

  const classes = useStyles(props)

  const [open, setOpen] = useState(false)

  const elevation = useMemo(() => {
    if (width !== "lg" && width !== "xl") return 1
    else return scroll ? 1 : 0
  }, [scroll, width])

  const handleImageClick = useCallback(
    () => {
      // history.push(RouterPath.HOME)
      scrollRS.scrollToTop()
    },
    [],
  )

  return (
    <Box className={classes.mainContainer}>
      <AppBarUI
        className={
          clsx(
            { [classes.appBarScroll]: scroll && (width === "lg" || width === "xl") },
            classes.appBar
          )
        }
        elevation={elevation}
        position='static'
      >
        <Toolbar className={classes.toolbar}>
          <Box>
            <img
              src={(width !== "lg" && width !== "xl") ? LogoDoItMobile : LogoDoIt}
              alt='Company Logo'
              className={clsx(
                { [classes.logoScroll]: scroll && (width === "lg" || width === "xl") },
                classes.logo
              )}
              onClick={handleImageClick}
            />
          </Box>
          <Hidden lgUp>
            <Box style={{ flexGrow: 1 }} />
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          {
            (width === "lg" || width === "xl") ?
              <Links />
              :
              <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen()}
              >
                <Links onClose={() => setOpen()} />
              </Drawer>
          }
        </Toolbar>
      </AppBarUI>
    </Box>
  )

}

export default memo(withWidth()(AppBar));
