import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BackgroundImage from '../images/background.jpg'
import GridBlock from '../components/GridBlock';
import { Switch, Route } from 'react-router-dom'
import RouterPath from '../const/RouterPath';
import Home from '../pages/Home/Home';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${BackgroundImage})`,
    color: 'white',
    height: '100vh'
  },

}))

function Header(props) {
  const classes = useStyles(props)

  return (
    <GridBlock
      id='home'
      alignItems='center'
      justify='center'
      direction='column'
      className={classes.container}
    >

      <Switch>
        <Route exact path={RouterPath.HOME} component={Home} />
        <Route component={Home} />
      </Switch>

    </GridBlock>
  )
}

export default memo(Header)
