import React, { memo } from 'react'
import { Typography } from '@material-ui/core';
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import RouterPath from '../../const/RouterPath';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EstimationForm from './EstimationForm';


const useStyles = makeStyles(theme => ({
  estimation: {
    background: theme.palette.lightBackground.main
  },
  cardBox: {
    background: 'white',
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse",
    },
    "&>:first-child": {
      width: '75%',
      borderBottomLeftRadius: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        width: "unset",
          borderTopLeftRadius: 'unset',
          borderBottomLeftRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
      }
    },
    "&>:nth-child(2)": {
      textAlign: "justify",
      padding: theme.spacing(3),
      width: '25%',
      background: theme.palette.primary.main,
      color: 'white',
      borderBottomRightRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
    
      [theme.breakpoints.down('md')]: {
        width: "unset",
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          borderBottomRightRadius: 'unset'
      }
    }
  },
  avatar: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2),
  },
  contentWrapper:{
    marginTop: theme.spacing(2),
    whiteSpace: "pre-wrap"
  }
}))

function Estimation(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <ContentContainer
      title={t("estimation_title")}
      id={RouterPath.anchors.ESTIMATION}
      className={classes.estimation}
    >
      <GridBlock justify="space-between" className={classes.cardBox}>
        <EstimationForm />
        <GridBlock justify="space-between" alignItems="center" direction="column">
          <ThumbUpIcon className={classes.avatar} />
          <Typography variant='body1'>
            {t("estimation_title")}
          </Typography>
          <Typography variant='body1' className={classes.contentWrapper}>
            {t("estimation_content")}
          </Typography>
        </GridBlock>
      </GridBlock >
    </ContentContainer >
  )
}

export default memo(Estimation)
