import React, { memo } from 'react'
import Link from './Link'
import RouterPath from '../const/RouterPath';
import { useTranslation } from 'react-i18next';

function Links({onClose}) {
  const { t } = useTranslation();

  return <>
    <Link onClose={onClose} label={t('home')} to={RouterPath.anchors.HOME} />
    <Link onClose={onClose} label={t('about')} to={RouterPath.anchors.ABOUT} />
    <Link onClose={onClose} label={t('technologies')} to={RouterPath.anchors.TECHNOLOGIES} />
    <Link onClose={onClose} label={t('projects')} to={RouterPath.anchors.PROJECTS} />
    {/* <Link onClose={onClose} label={t('team')} to={RouterPath.anchors.TEAM} /> */}
    <Link onClose={onClose} label={t('clients')} to={RouterPath.anchors.CLIENTS} />
    <Link onClose={onClose} label={t('estimation')} to={RouterPath.anchors.ESTIMATION} />
    <Link onClose={onClose} label={t('contact')} to={RouterPath.anchors.CONTACT} />
  </>
}

export default memo(Links)
