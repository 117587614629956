import React, { memo } from 'react'
import { Typography, Box } from '@material-ui/core';
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
import AboutImage from '../../images/about.png'
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import RouterPath from '../../const/RouterPath';

const useStyles = makeStyles(theme => ({
  about: {
    background: "black",
    color: "white",
  },
  container: {
    padding: '0 100px',
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      alignContent: "center",
      padding: '0 50px',
    },
    "&>:first-child": {
      textAlign: "justify",
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: "unset"
      },
    },
    "&>:nth-child(2)": {
      '& img': {
        [theme.breakpoints.only('md')]: {
          width: 300
        },
        [theme.breakpoints.down('sm')]: {
          width: 200
        },
      },
      [theme.breakpoints.down('md')]: {
        textAlign: "center",
        marginTop: theme.spacing(3),
      }
    },
  },
  subContent:{
    marginTop: theme.spacing(2),
  }
}))

function About(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <ContentContainer
      title={t("about_title")}
      className={classes.about}
      id={RouterPath.anchors.ABOUT}
    >
      <GridBlock justify="space-between" className={classes.container}>
        <Box>
          <Typography variant='body1'>
            {t("about_content")}
          </Typography>
          <Typography variant='body1' className={classes.subContent}>
            {t("about_subContent")}
          </Typography>
        </Box>
        <img alt="AboutImage" src={AboutImage} />
      </GridBlock>
    </ContentContainer>
  )
}

export default memo(About)
