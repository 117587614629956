import React, { memo } from 'react'
import { FormControlLabel, Radio as RadioUI } from '@material-ui/core';

function Radio({
  label,
  value,
  disabled,
  className,
  labelClassName,
}) {

  return <FormControlLabel
        value={value}
        className={className}
        control={<RadioUI color="primary"/>}
        label={label}
        classes={{
          label: labelClassName
        }}
        disabled={disabled}
      />
}

export default memo(Radio)
