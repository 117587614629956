import React from 'react'

export default (props) => {
  return (
    <svg {...props} version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="-243 245 24 24" space="preserve">
    <path d="M-223,249h-16c-1.1,0-2,0.9-2,2l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2v-12C-221,249.9-221.9,249-223,249z M-223,253l-8,5
      l-8-5v-2l8,5l8-5V253z"/>
    </svg>
  )
}