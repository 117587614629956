import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core';
import GridBlock from './GridBlock';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
    }
  },
  box: {
    width: 40,
    height: 25,
    background: `linear-gradient(90deg, rgba(255,0,0,0) 0%, ${theme.palette.primary.main} 100%)`,
    marginRight: theme.spacing(2)
  }
}))

function Title({ props, title }) {
  const classes = useStyles(props)
  return (
    <GridBlock
      alignItems="center"
      className={classes.container}
    >
      <Box className={classes.box} />
      <Typography variant="h5">
        {title.toUpperCase()}
      </Typography>
    </GridBlock>
  )
}

export default memo(Title)
