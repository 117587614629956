import React, { memo, useMemo } from 'react'
import ContentContainer from '../../components/ContentContainer';
import { makeStyles } from '@material-ui/core/styles'
import GridBlock from '../../components/GridBlock';
import { useTranslation } from 'react-i18next';
import SkillsBackground from '../../images/Artboard.png';
import { Typography } from '@material-ui/core';
import { Parallax } from 'react-parallax';

const useStyles = makeStyles(theme => ({
  skills: {
    ...theme.maxResolution,
    height: 400,
    color: theme.palette.primary.main,
  },
  container: {
    paddingRight: theme.spacing(4),
  },
  skillText: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
}))

const trList = [
  "analysis",
  "project_management",
  "direct_work_with_client",
  "outsourcing",
  "fixed_price",
  "time_material",
]

function Skills(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const list = useMemo(() => trList.map((x, i) => {
    return <Typography
      key={i}
      variant="h6"
      className={classes.skillText}
    >
      {t(x).toUpperCase()}
    </Typography>
  }), [t, classes])

  return (
    <Parallax
      bgImage={SkillsBackground}
      bgImageAlt="skills list"
      strength={250}
    >
      <ContentContainer
        className={classes.skills}
        //   title={t("skills_title")}
        alignContent="flex-end"
        alignItems="flex-end"
        justify="center"
      >
        <GridBlock alignItems="flex-end" direction="column" className={classes.container}>
          {list}
        </GridBlock>
      </ContentContainer>
    </Parallax>
  )
}

export default memo(Skills)
